import React from "react"
import {
    Input,
    Label,
} from "reactstrap"
import { InputType } from "reactstrap/types/lib/Input"

export const LabeledInput = ({ type, title, value, onChange }: {
    type: InputType,
    title: string,
    value: any,
    onChange: (value: any) => void,
}) => (
    <>
        <Label className="me-2">
            {title}
        </Label>
        <Input
            type={type}
            className="mb-3"
            placeholder={title}
            value={value}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (type === "checkbox") {
                    return onChange(e.target.checked)
                }
                onChange(e.target.value)
            }}
        />
    </>
)

export const LabeledTextBox = (({ title, value, type, onChange, onStateChange }: {
    title: string,
    value: number,
    type: InputType,
    onChange: (value: any) => void,
    onStateChange: (value: any) => void,
}) => {
    const handleChange = (isBlur: boolean) => (value: any) => {
        if (!isNaN(parseFloat(value))) {
            onChange(value)
            return
        }
        if (isBlur) {
            onChange(0)
        }
        onStateChange(value)
    }

    return <>
        <div className="d-flex justify-content-between align-items-center mb-2 text-muted">
            <Label className="mb-0">{title}</Label>
            <div className="d-flex">
                <Input
                    className="labelInput"
                    bsSize="sm"
                    value={value}
                    type={type}
                    onChange={(e) => {
                        handleChange(false)(e.target.value)
                    }}
                    onBlur={(e) => {
                        handleChange(true)(e.target.value)
                    }}
                />
            </div>
        </div>
    </>
})

export const LabeledCheckBox = ({ title, checked, onChange }: {
    title: string,
    checked: boolean,
    onChange: (value: boolean) => void,
}) => (
    <>
        <Label className="me-2">
            {title}
        </Label>
        <Input
            type="checkbox"
            className="mb-3"
            checked={checked}
            placeholder={title}
            onClick={() => {
                onChange(!checked)
            }}
            onChange={(e) => {
                onChange(e.target.checked)
            }}
        />
    </>
)
