import React, { useEffect, useState } from "react"
import { Table } from "reactstrap"

import "mapbox-gl/dist/mapbox-gl.css"
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css"

import { calculateMidpoint } from "../../components/Map/calculate_mid"
import { PointCloudPlace } from "./pointCloudHook"

interface TablePointCloudProps {
  places: PointCloudPlace[]
  map?: mapboxgl.Map
}

export default function TablePointCloud({
  places,
  map,
}: TablePointCloudProps) {

  const DisplayPlaces = places.map((info, index) => {
    const handleCellClick = (coords: number[][]) => {
      if (!map) return
      var midpoint = calculateMidpoint(coords[0])
      map.flyTo({
        center: midpoint,
        essential: true, // this animation is considered essential with respect to prefers-reduced-motion
        zoom: 18,
        speed: 1.5,
        curve: 1.5,
      })
      console.log(coords)
      window.scrollTo({ top: 0, behavior: "smooth" })
    }
    return (
      <tr onClick={() => handleCellClick(info.location.coordinates)}>
        <td>{index + 1}</td>
        <td>{info.name}</td>
        <td>{info.category}</td>
      </tr>
    )
  })

  return (
    <React.Fragment>
      <Table
        bordered
        style={{ marginTop: "50px", marginBottom: "50px" }}
        className="table-primary"
      >
        <thead>
          <tr>
            <th>#</th>
            <th>Name</th>
            <th>Category</th>
          </tr>
        </thead>
        <tbody>{DisplayPlaces}</tbody>
      </Table>
    </React.Fragment>
  )
}