export const calculateMidpoint = (coords: number[][]): [number, number] => {
    var totalLat = 0
    var totalLng = 0
    var totalPoints = coords.length
  
    for (var i = 0; i < coords.length; i++) {
      totalLat += coords[i][0]
      totalLng += coords[i][1]
    }
  
    var avgLat = totalLat / totalPoints
    var avgLng = totalLng / totalPoints
  
    return [avgLat, avgLng]
  }