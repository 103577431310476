import { Canvas } from "@react-three/fiber";
import { Suspense } from "react";

import { DraggableBox } from "./draggable_box";
import { MapControls } from "./map_controls";

export default function ThreeModel() {
  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100vw",
        height: "85vh",
        overflow: "hidden",
      }}
      
    >
      <Canvas dpr={[1, 2]} camera={{ position: [0, 12, 2] }}>
        <ambientLight />
        <pointLight position={[150, 150, 150]} intensity={1} />
        <MapControls>
          <Suspense fallback={null}>
            <DraggableBox />
          </Suspense>
        </MapControls>
        {/* <gridHelper /> */}
      </Canvas>
    </div>
  );
};
