import React, { useEffect, useRef, useState } from "react"
import mapboxgl, { FillExtrusionLayer, CustomLayerInterface } from "mapbox-gl"
import * as THREE from "three"
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader"
import { Map, Layer, Marker, useMap } from "react-map-gl"

// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN!
const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN!
const mapCenterCoor: [number, number] = [100.61134507546271, 13.68515190000507] // TDPK

export default function Map3DModel() {
  const { current: map } = useMap()

  let camera: THREE.Camera
  let scene: THREE.Scene
  let renderer: THREE.WebGLRenderer
  let mixer: THREE.AnimationMixer

  const modelOrigin = mapCenterCoor
  const modelAltitude = 0
  const modelRotate = [Math.PI / 2, 0, 0]

  const modelAsMercatorCoordinate = mapboxgl.MercatorCoordinate.fromLngLat(
    modelOrigin,
    modelAltitude
  )

  const modelTransform = {
    translateX: modelAsMercatorCoordinate.x,
    translateY: modelAsMercatorCoordinate.y,
    translateZ: modelAsMercatorCoordinate.z!,
    rotateX: modelRotate[0],
    rotateY: modelRotate[1],
    rotateZ: modelRotate[2],
    /* Since the 3D model is in real world meters, a scale transform needs to be
     * applied since the CustomLayerInterface expects units in MercatorCoordinates.
     */
    scale: modelAsMercatorCoordinate.meterInMercatorCoordinateUnits() * 10,
  }

  const coinModelLayer: CustomLayerInterface = {
    id: "3d-model",
    type: "custom",
    renderingMode: "3d",
    onAdd: function (map, gl) {
      camera = new THREE.PerspectiveCamera()
      scene = new THREE.Scene()

      // create two three.js lights to illuminate the model
      const directionalLight = new THREE.DirectionalLight(0xffff)
      directionalLight.position.set(0, -70, 100).normalize()
      scene.add(directionalLight)

      const directionalLight2 = new THREE.DirectionalLight(0xff2)
      directionalLight2.position.set(0, 70, 100).normalize()
      scene.add(directionalLight2)

      // use the three.js GLTF loader to add the 3D model to the three.js scene
      const loader = new GLTFLoader()
      loader.load(
        "https://docs.mapbox.com/mapbox-gl-js/assets/34M_17/34M_17.gltf",
        gltf => {
          scene.add(gltf.scene)
          console.log("success")
        }
      )

      // use the Mapbox GL JS map canvas for three.js
      renderer = new THREE.WebGLRenderer({
        canvas: map.getCanvas(),
        context: gl,
        antialias: true,
      })

      renderer.autoClear = false

      // animate();
    },
    render: function (gl, matrix) {
      const rotationX = new THREE.Matrix4().makeRotationAxis(
        new THREE.Vector3(1, 0, 0),
        modelTransform.rotateX
      )
      const rotationY = new THREE.Matrix4().makeRotationAxis(
        new THREE.Vector3(0, 1, 0),
        modelTransform.rotateY
      )
      const rotationZ = new THREE.Matrix4().makeRotationAxis(
        new THREE.Vector3(0, 0, 1),
        modelTransform.rotateZ
      )

      const m = new THREE.Matrix4().fromArray(matrix)
      const l = new THREE.Matrix4()
        .makeTranslation(
          modelTransform.translateX,
          modelTransform.translateY,
          modelTransform.translateZ
        )
        .scale(
          new THREE.Vector3(
            modelTransform.scale,
            -modelTransform.scale,
            modelTransform.scale
          )
        )
        .multiply(rotationX)
        .multiply(rotationY)
        .multiply(rotationZ)

      camera.projectionMatrix = m.multiply(l)
      renderer.resetState()
      renderer.render(scene, camera)
      if (!map) return
      map.triggerRepaint()
    },
  }

  return (
    <React.Fragment>
      <Map
        initialViewState={{
          latitude: mapCenterCoor[1],
          longitude: mapCenterCoor[0],
          zoom: 15,
          pitch: 60, // VIEW ORIENTATION
        }}
        style={{ height: "80vh" }}
        mapStyle="mapbox://styles/mapbox/satellite-streets-v12"
        mapboxAccessToken={MAPBOX_TOKEN}
      >
        <Layer {...coinModelLayer} />
      </Map>
    </React.Fragment>
  )
}
