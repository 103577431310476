import { createOrUpdateFloor, getFloors, updateMultiFloor } from "api/floor"
import { useEffect, useState } from "react"

export interface Floor {
    _id?: string
    displayName?: string
    placeId: string
    index: number
    isDefault: boolean
    yMin?: number
    yMax?: number
}

export const useFloor = (placeId: string) => {
    const { floors, onFloorDataChange, refreshFloor } = useFloorsData(placeId)
    const [selectedFloor, setSelectedFloor] = useState<Floor | undefined>()

    const onFloorChange = (floor: Floor) => {
        onFloorStateChange(floor)
        createOrUpdateFloor(floor)
    }

    const onFloorStateChange = (floor: Floor) => {
        const newFloors = [...floors]
        const idx = newFloors.findIndex(f => f._id === floor._id)
        if (idx === -1) return
        newFloors[idx] = floor
        onFloorDataChange(newFloors)
        if (floor._id === selectedFloor?._id) {
            setSelectedFloor(floor)
        }
    }

    const getDefaultFLoor = (): Floor => ({
        placeId,
        index: floors.length,
        isDefault: floors.length === 0,
        displayName: floors.length.toString(),
    })

    const onFloorAdd = () => {
        const index = selectedFloor?.index || floors.length
        const newFloor = getDefaultFLoor()
        onFloorIndexChange(newFloor, index)
    }

    const onFloorIndexChange = (updatedFloor: Floor, updatedIndex: number) => {
        const newFloors: Floor[] = []
        // debugger;
        if (updatedIndex < 0 || ((updatedIndex >= floors.length) && updatedFloor._id)) return
        if (updatedIndex >= floors.length && !updatedFloor._id) {
            // add new floor from 0
            newFloors.push(...floors, {
                ...updatedFloor,
                index: floors.length
            })
        } else {
            floors.forEach((floor) => {
                if (floor._id === updatedFloor._id) return
                if (newFloors.length !== updatedIndex) {
                    const newFloor: Floor = {
                        ...floor,
                        index: newFloors.length,
                    }
                    newFloors.push(newFloor)
                } else {
                    const newUpdatedFloor = {
                        ...updatedFloor,
                        index: newFloors.length
                    }
                    const newFloor: Floor = {
                        ...floor,
                        index: newFloors.length + 1,
                    }
                    newFloors.push(newUpdatedFloor, newFloor)
                }
            })
            if (updatedIndex === floors.length - 1 && updatedFloor._id) {
                const newFloor: Floor = {
                    ...updatedFloor,
                    index: newFloors.length,
                }
                newFloors.push(newFloor)
            }
        }

        updateMultiFloor(newFloors).then(res => {
            if (res) {
                refreshFloor()
            }
        })
    }

    const onFloorDelete = (floorToDelete: Floor) => {
        const newFloors: Floor[] = []
        floors.forEach((floor) => {
            if (floor._id === floorToDelete._id) return
            const newFloor: Floor = {
                ...floor,
                index: newFloors.length,
            }
            newFloors.push(newFloor)
        })

        updateMultiFloor(newFloors).then(res => {
            if (res) {
                refreshFloor()
            }
        })
    }

    const onFloorSelect = (floor?: Floor) => {
        setSelectedFloor(floor)
    }

    useEffect(() => {
        if (selectedFloor) {
            const updatedSelectedFloor = floors.find(floor => floor._id === selectedFloor._id)
            setSelectedFloor(updatedSelectedFloor)
        }
    }, [floors])

    return { floors, onFloorIndexChange, onFloorAdd, onFloorChange, onFloorStateChange, onFloorDelete, onFloorSelect, selectedFloor }
}

const sortFloor = (floors: Floor[]) => {
    const sortedFloor: Floor[] = []
    floors.forEach((_floor, index) => {
        const selectedFloor = floors.find(floor => floor.index === index)
        if (selectedFloor) sortedFloor.push(selectedFloor)
    })
    return sortedFloor
}

export const useFloorsData = (placeId: string) => {
    const [floors, setFloors] = useState<Floor[]>([])
    const [isFetching, setIsFetching] = useState(false);

    const onFloorDataChange = (floors: Floor[]) => {
        setFloors(floors)
    }

    const refreshFloor = () => {
        try {
            setIsFetching(true);
            getFloors(placeId).then(data => {
                setFloors(sortFloor(data))
                setIsFetching(false)
            });
        } catch (e) {
            console.error(e);
            setIsFetching(false)
        }
    }

    useEffect(() => {
        try {
            setIsFetching(true);
            getFloors(placeId).then(data => {
                setFloors(sortFloor(data))
                setIsFetching(false)
            });
        } catch (e) {
            console.error(e);
            setIsFetching(false)
        }
    }, []); // Runs once

    return { floors, onFloorDataChange, refreshFloor, isFetching };
}
