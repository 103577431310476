import { GetUser } from "components/Map/fetch_func";
import React, { useEffect, useState } from "react"
import { Table } from "reactstrap"

interface User {
  created_at: number;
  display_name: string;
  email: string;
}

export default function MappersUser() {
  const [users, setUsers] = useState<User[]>([])

  useEffect(() => {
    GetUser()
      .then(data => setUsers(data))
      .catch(error => console.error('Error fetching data:', error));
  }, [])

  const formatTimestamp = (timestamp: number): string => {
    const date = new Date(timestamp);
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'short' });
    const year = date.getFullYear();

    // Pad single-digit day with leading zero
    const formattedDay = day < 10 ? `0${day}` : `${day}`;

    const formattedTime = date.toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
    });

    return `${formattedDay}/${month}/${year.toString().slice(-2)} ${formattedTime}`;
  };

  const DisplayUsers = users.map((info, index) => {
    return (
      <tr>
        <td>{index + 1}</td>
        <td>{formatTimestamp(info.created_at)}</td>
        <td>{info.display_name}</td>
        <td>{info.email}</td>
      </tr>
    )
  })

  return (
    <React.Fragment>
      <Table bordered style={{ marginTop: "100px", marginBottom: "100px" }} className="table-primary">
        <thead>
          <tr>
            <th>#</th>
            <th>Create At</th>
            <th>Display name</th>
            <th>Email</th>
          </tr>
        </thead>
        <tbody>{DisplayUsers}</tbody>
      </Table>
    </React.Fragment>
  )
}
