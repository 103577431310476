import axios from "axios"
import { generateBaseHeader } from "./apiHelper";
import getBackendUrl from "const";
import { PointCloudPlace } from "common/hooks/placeHook";

const backendUrl = getBackendUrl()

export const getPointClouds = async (): Promise<PointCloudPlace[]> => {
    const header = await generateBaseHeader()
    const resp = await axios({
        method: "GET",
        url: backendUrl + "/point_cloud",
        headers: {
            ...header,
        },
    })

    if (resp.status === 200) {
        return resp.data;
    }
    console.error("getPipelines fail");
    return [];
}

export const getPlace = async (id: string): Promise<PointCloudPlace | undefined> => {
    const header = await generateBaseHeader()
    const resp = await axios({
        method: "GET",
        url: backendUrl + "/place/" + id,
        headers: {
            ...header,
        },
    })

    if (resp.status === 200) {
        return resp.data;
    }
    console.error("getPlace fail");
    return;
}

export const createPlace = async (pointCloudPlace: PointCloudPlace): Promise<boolean> => {
    const header = await generateBaseHeader()
    try {
        const resp = await axios({
            method: "POST",
            url: backendUrl + "/create_places",
            headers: {
                ...header,
            },
            data: JSON.stringify(pointCloudPlace),
        })

        if (resp.status === 200) {
            return true;
        }
    } catch (error) {
        console.error(error)
    }
    return false;
}

export const updatePlace = async (pointCloudPlace: PointCloudPlace): Promise<boolean> => {
    const header = await generateBaseHeader()
    try {
        const resp = await axios({
            method: "POST",
            url: backendUrl + "/update_places",
            headers: {
                ...header,
            },
            data: JSON.stringify(pointCloudPlace),
        })

        if (resp.status === 200) {
            return true;
        }
    } catch (error) {
        console.error(error)
    }
    return false;
}
