
import { ControlMode } from '../r3f/TransformControl'
import './toolsBar.scss'

const controlItems = [
    {
        type: ControlMode.Translate,
        icon: "bi bi-arrows-move",
    }, {
        type: ControlMode.Rotate,
        icon: "bi bi-arrow-repeat",
    },
]


interface ToolBarProps {
    onControlModeChange: (newControlMode: ControlMode) => void
    selectedControlMode: ControlMode
}

const ToolsBar = ({ onControlModeChange, selectedControlMode }: ToolBarProps) => {
    return <div className="toolsBar">
        {controlItems.map(controlItem => <div
            key={controlItem.type}
            className={`toolItem ${selectedControlMode === controlItem.type ? 'toolItem--selected' : ''}`}
            onClick={() => onControlModeChange(controlItem.type)}
            data-testid={`change-control-${controlItem.type}`}
        >
            <i className={`${controlItem.icon} toolIcon`} color="#404856" />
        </div>)}
        {/* <div style={{ height: 20 }} className="border-end border-secondary ms-2 me-2"></div> */}
    </div>
}

ToolsBar.displayName = "ToolsBar"

export { ToolsBar }
