import { useState } from 'react'
import './floorSelector.scss'
import { Button, Popover } from 'reactstrap';
import { Floor } from '../floorHook';

interface floorSelectorProps {
    floors: Floor[]
    onFloorSelect: (floorId?: string) => void
    selectedFloor?: Floor
}

const FloorSelector = ({
    floors,
    selectedFloor,
    onFloorSelect,
}: floorSelectorProps) => {
    if (floors.length <= 0) return <></>

    return <div className="floorSelectorContainer">
        <Button
            color="white"
            className={`rounded-bottom rounded-0 bg-light p-0 ${!selectedFloor ? "opacity-100" : "opacity-75"}`}
            style={{ width: 40, height: 40 }}
            onClick={() => onFloorSelect()}
        >
            All
        </Button>
        {
            floors.map((floor, index) => (
                <Button
                    color="white"
                    key={floor._id}
                    className={`${index === (floors.length - 1) ? "rounded-0 rounded-top": "rounded-0"} bg-light p-0 ${floor._id === selectedFloor?._id ? "opacity-100" : "opacity-75"}`}
                    style={{ width: 40, height: 40 }}
                    onClick={() => onFloorSelect(floor._id)}
                >
                    {floor.displayName}
                </Button>
            ))
        }
    </div>
}

export { FloorSelector }
