import { useState } from "react"
import {
    Col,
    Container,
    Row,
} from "reactstrap"

import { useSearchParams } from "react-router-dom"
import { usePlace, usePlaceData } from "common/hooks/placeHook"
import { CoordinateEditor } from "./CoordinateEditor"
import { CoordinateMenu } from "./CoordinateMenu"
import { ControlMode } from "common/components/r3f/TransformControl"
import { ToolsBar } from "common/components/ToolBar/ToolBar"

export default function Coordinate() {
    const [urlParams] = useSearchParams();
    const placeId = urlParams.get('place_id');
    const [defaultPlace] = usePlaceData(placeId || "")
    const { place, submitPlace, updatePlaceState } = usePlace(defaultPlace)
    const [controlMode, setControlMode] = useState<ControlMode>(ControlMode.Translate)

    if (!placeId || !place) return

    const origin = defaultPlace?.originCoordinate?.displayOriginCoordinates || defaultPlace?.location?.coordinates[0][0] || []

    return (
        <div style={{ paddingTop: "70px", paddingBottom: "60px", height: "100vh" }}>
            <ToolsBar
                selectedControlMode={controlMode}
                onControlModeChange={(newMode) => {setControlMode(newMode)}}
            />
            <Container fluid className="h-100 g-0" >
                <Row className="h-100" >
                    <Col lg={8} className="align-items-center h-100 g-0">
                        <CoordinateEditor
                            selectedControlMode={controlMode}
                            place={place}
                            origin={origin}
                            onPlaceUpdate={updatePlaceState}
                            // onPlaceUpdate={submitPlace}
                        />
                    </Col>
                    <Col lg={4} className="h-100 g-0">
                        <CoordinateMenu
                            place={place}
                            onPlaceChange={updatePlaceState}
                            onPlaceSubmit={submitPlace}
                        />
                    </Col>
                </Row>
            </Container>
        </div>
    )
}
