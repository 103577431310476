import React from "react"
import "mapbox-gl/dist/mapbox-gl.css"
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css"

import MapBox from "components/Map/mappers-controls/mapbox"

export default function MappersControls() {
  return (
    <React.Fragment>
        <MapBox/>
    </React.Fragment>
  )
}
