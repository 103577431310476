import { createPlace, getPlace, getPointClouds, updatePlace } from "api/pointcloud"
import { Reconstruction } from "modules/ScanningData/scanningDataHook"
import React, { useCallback, useEffect, useState } from "react"

export enum PlaceType {
    Indoor = "indoor",
    Outdoor = "outdoor",
}

export enum PlaceAccessType {
    Private = "private",
    Public = "public",
}

export interface PointCloudData {
    reconstruction_id: string
    features: string
    db_descriptors: string
    idx2img: string
    sfm_images: string
    sfm_points3D: string
}

export interface PolygonLocation {
    Type: "Polygon"
    coordinates: [number, number][][]
}

export interface PointCloudPlace {
    _id?: string
    name: string
    category: string
    organization_id?: string
    data: PointCloudData
    type: PlaceType
    access_type: PlaceAccessType
    public_point_cloud_file: string
    signed_public_point_cloud_file?: string
    public_splat_file?: string
    signed_public_splat_file?: string
    maxtvec: number
    location?: PolygonLocation
    originCoordinate?: PlaceOrigin
}

export interface Vector3 {
    x: number
    y: number
    z: number
}

export interface Quaternion {
    w: number
    x: number
    y: number
    z: number
}

export interface LocationPoint {
    type: 'Point'
    coordinates: number[]
}

export interface PlaceOrigin {
    displayOriginCoordinates: number[]
    displayRotation: Quaternion
}

export const usePlacesData = () => {
    const [pointClouds, setPointClouds] = useState<PointCloudPlace[]>([])
    useEffect(() => {
        getPointClouds().then(data => {
            setPointClouds(data)
        })
    }, [])

    return [pointClouds]
}

export const usePlaceData = (id: string) => {
    const [pointCloud, setPointCloud] = useState<PointCloudPlace>()
    useEffect(() => {
        getPlace(id).then(data => {
            setPointCloud(data)
        })
    }, [])

    return [pointCloud]
}

export const usePlace = (
    defaultPlace?: PointCloudPlace,
) => {
    const [place, setPlace] = useState<PointCloudPlace | undefined>(defaultPlace)

    const updatePlaceState = (newPlace: PointCloudPlace) => {
        setPlace(newPlace)
    }

    const submitPlace = async (): Promise<boolean> => {
        if (!place) return false
        if (!place.location) {
            alert("Please draw point cloud area before creation")
            return false
        }
        if (place._id) {
            const res = await updatePlace(place)
            return res
        }
        const res = await createPlace(place)
        return res
    }

    // TODO: Delete Place

    useEffect(() => {
        if (defaultPlace) {
            setPlace(defaultPlace)
        }
    }, [defaultPlace])

    return {
        place,
        updatePlaceState,
        submitPlace,
    }
}
