import firebase from 'firebase/compat/app';

// Add the Firebase products that you want to use
import "firebase/compat/auth";
import "firebase/compat/firestore";

const expireTime = 3600 * 1000; // expire after 1 hour
const cookieExpire = new Date(Date.now() + expireTime)

interface FirebaseConfig {
  apiKey: string;
  authDomain: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
}

class FirebaseAuthBackend {
  isLoginUser: boolean | undefined;
  constructor(firebaseConfig: FirebaseConfig) {
    if (firebaseConfig) {
      // Initialize Firebase
      firebase.initializeApp(firebaseConfig);
      firebase.auth().onAuthStateChanged(user => {
        if (user) {
          user.getIdToken(true).then((token: string) => {
            document.cookie = `firebase.token=${token};expires=${cookieExpire.toUTCString()}`;
          })
          this.isLoginUser = true;
          localStorage.setItem("authUser", JSON.stringify(user));
        } else {
          localStorage.removeItem("authUser");
        }
      });
    }
  }

  /**
   * Registers the user with given details
   */
  registerUser = (email: string, password: string) => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .createUserWithEmailAndPassword(email, password)
        .then(
          user => {
            resolve(firebase.auth().currentUser);
          },
          error => {
            reject(this._handleError(error));
          }
        );
    });
  };

  /**
   * Registers the user with given details
   */
  editProfileAPI = (email: string, password: string) => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .createUserWithEmailAndPassword(email, password)
        .then(
          user => {
            resolve(firebase.auth().currentUser);
          },
          error => {
            reject(this._handleError(error));
          }
        );
    });
  };

  /**
   * Login user with given details
   */
  loginUser = (email: string, password: string) => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then(
          user => {
            resolve(firebase.auth().currentUser);
          },
          error => {
            reject(this._handleError(error));
          }
        );
    });
  };

  logoutUser = () => {
    localStorage.removeItem("authUser");
    document.cookie = `firebase.token=;expires=${new Date().toUTCString()}`
    this.isLoginUser = false;
  }

  /**
   * forget Password user with given details
   */
  forgetPassword = (email: string) => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .sendPasswordResetEmail(email, {
          url:
            window.location.protocol + "//" + window.location.host + "/login",
        })
        .then(() => {
          resolve(true);
        })
        .catch(error => {
          reject(this._handleError(error));
        });
    });
  };

  /**
   * Logout the user
   */
  logout = () => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .signOut()
        .then(() => {
          resolve(true);
        })
        .catch(error => {
          reject(this._handleError(error));
        });
    });
  };

  /**
  * Social Login user with given details
  */

  socialLoginUser = async (type: string) => {
    let provider;
    if (type === "google") {
      provider = new firebase.auth.GoogleAuthProvider();
    } else if (type === "facebook") {
      provider = new firebase.auth.FacebookAuthProvider();
    }
    if (!provider) throw this._handleError("Unable to sign in. Please try again");
    try {
      const result = await firebase.auth().signInWithPopup(provider);
      const user = result.user;

      // Check if the email domain matches "@graffity.tech"
      if (type === "google" && user?.email?.endsWith("@graffity.tech")) {
        return user;
      } else if (type === "facebook") {
        return user;
      } else {
        throw new Error("Invalid email domain. Please sign in with an email from the '@graffity.tech' domain.");
      }
    } catch (error) {
      throw this._handleError(error);
    }
  };


  addNewUserToFirestore = (user: any) => {
    const collection = firebase.firestore().collection("users");
    const { profile } = user.additionalUserInfo;
    const details = {
      firstName: profile.given_name ? profile.given_name : profile.first_name,
      lastName: profile.family_name ? profile.family_name : profile.last_name,
      fullName: profile.name,
      email: profile.email,
      picture: profile.picture,
      createdDtm: firebase.firestore.FieldValue.serverTimestamp(),
      lastLoginTime: firebase.firestore.FieldValue.serverTimestamp()
    };
    collection.doc(firebase.auth().currentUser?.uid).set(details);
    return { user, details };
  };

  setLoggeedInUser = (user: any) => {
    localStorage.setItem("authUser", JSON.stringify(user));
  };

  getCurrentUser = () => {
    return firebase.auth().currentUser;
  };

  getTokenAfterInit = () => {
    const waitForRefreshedToken = (
      resolve: (i: string) => void,
      reject: (i: string) => void
    ) => {
      if (this.isLoginUser !== undefined) {
        const user = this.getCurrentUser()
        if (!user) {
          reject("logout user")
          return
        }
        user.getIdToken().then((token: string) => {
          document.cookie = `firebase.token=${token};expires=${cookieExpire.toUTCString()}`;
          resolve(token)
        })
      } else {
        setTimeout(waitForRefreshedToken.bind(this, resolve, reject), 200);
      }
    }
    return new Promise(waitForRefreshedToken)
  }

  getRefreshedToken = async () => {
    return await this.getTokenAfterInit().then((result => {
      return result;
    })).catch(_ => {
      this.logoutUser()
    })
  }

  /**
   * Handle the error
   * @param {*} error
   */
  _handleError(error: any) {
    // var errorCode = error.code;
    var errorMessage = error.message;
    return errorMessage;
  }
}

let _fireBaseBackend: FirebaseAuthBackend;

/**
 * Initilize the backend
 * @param {*} config
 */
const initFirebaseBackend = (config: FirebaseConfig) => {
  if (!_fireBaseBackend) {
    _fireBaseBackend = new FirebaseAuthBackend(config);
  }
  return _fireBaseBackend;
};

/**
 * Returns the firebase backend
 */
const getFirebaseBackend = () => {
  return _fireBaseBackend;
};

export { initFirebaseBackend, getFirebaseBackend };
