import { createReconstruction, getPipelines, getReconstructions } from "api/reconstruction";
import { LngLatLike } from "mapbox-gl";
import { useCallback, useEffect, useState } from "react";

export enum ReconstructionType {
    Pending = "pending",
    InProgress = "in_progress",
    Done = "done",
    Failed = "failed"
}

export enum PipelineType {
    Reconstruction = "reconstruction",
    Merge = "merge",
}

export interface Location2D {
    type: 'Point'
    coordinates: LngLatLike  // Long, La
}

export interface VideoPlacesTable {
    _id: string
    user_generated_uid: string
    display_name: string
    created_at: number
    download_url: string
    startlocation: Location2D
    endlocation: Location2D
    files_to_upload: string[]
    from_device: string
    from_platform: string
    name: string
    size_in_byte: number
    status: string
    updated_at: number
    user_id: string
}

export interface Reconstruction {
    id?: string
    pipeline_id: string
    organization_id: string
    parent_id: string
    pipeline_type: PipelineType
    scan_data: VideoPlacesTable
    storage_link?: string
    volume_size: string
    max_keypoints: number
    retrieval_top_k: number
    downsample_frame_rate: number
    created_at?: string
}

export interface Pipeline {
    id: string
    name: string
    pipeline_id: string
    version_id?: string
    type: PipelineType
    description?: string
    created_at: Date
}

const usePipelineData = () => {
    const [pipelines, setPipelines] = useState<Pipeline[]>([])
    useEffect(() => {
        getPipelines().then(data => {
            setPipelines(data)
        })
    }, [])

    return [pipelines]
}

export const useReconstructionsData = () => {
    const [reconstructions, setReconstructions] = useState<Reconstruction[]>([])
    useEffect(() => {
        getReconstructions().then(data => {
            setReconstructions(data)
        })
    }, [])

    return [reconstructions]
}

export const useReconstruction = (video?: VideoPlacesTable) => {
    const [pipelines] = usePipelineData()
    const [reconstructionsData] = useReconstructionsData()
    const [reconstruction, setReconstruction] = useState<Reconstruction>()

    const initReconstruction = (video: VideoPlacesTable) => {
        const newReconstruction: Reconstruction = {
            pipeline_id: pipelines?.[0]?.id || "",
            organization_id: "",
            parent_id: "",
            scan_data: video,
            volume_size: "50Gi",
            max_keypoints: 4096,
            retrieval_top_k: 10,
            downsample_frame_rate: 5,
            pipeline_type: pipelines?.[0]?.type || PipelineType.Reconstruction
        }

        setReconstruction(newReconstruction)
    }

    const updateReconstructionVideo = (video: VideoPlacesTable) => {
        if (!reconstruction) {
            initReconstruction(video)
            return
        }

        const newReconstruction: Reconstruction = {
            ...reconstruction,
            scan_data: video,
        }
        setReconstruction(newReconstruction)
    }

    const updateReconstruction = useCallback((key: keyof(Reconstruction)) => (value: any) => {
        if (!reconstruction) return
        if (key === "volume_size") {
            setReconstruction({
                ...reconstruction,
                volume_size: `${value}Gi`
            })
            return
        }
        if (key === "pipeline_id") {
            const selectedPipeline = pipelines.find(pipeline => pipeline.id === value)
            if (!selectedPipeline) return
            setReconstruction({
                ...reconstruction,
                pipeline_id: selectedPipeline?.id,
                pipeline_type: selectedPipeline?.type
            })
            return
        }
        setReconstruction({
            ...reconstruction,
            [key]: value
        })
    }, [reconstruction])

    const submitReconstruction = async(): Promise<boolean> => {
        if (!reconstruction) return false
        const res = await createReconstruction(reconstruction)
        return res
    }

    useEffect(() => {
        if (!!video) {
            updateReconstructionVideo(video)
        }
    }, [video])

    return {
        pipelines,
        reconstruction,
        reconstructionsData,
        updateReconstructionVideo,
        updateReconstruction,
        submitReconstruction,
    };
};