import { LabeledCheckBox, LabeledInput } from "components/Common/form"
import { Floor } from "./floorHook"
import { useCallback } from "react"
import { Button, Col, Row } from "reactstrap"

interface FloorMenuProps {
    selectedFloor: Floor | undefined
    onFloorChange: (floor: Floor) => void
    onFloorStateChange: (floor: Floor) => void
    onFloorIndexChange: (updatedFloor: Floor, updatedIndex: number) => void
    onFloorAdd: () => void
    onFloorDelete: (floor: Floor) => void
}

const FloorMenu = ({ selectedFloor, onFloorChange, onFloorStateChange, onFloorIndexChange, onFloorAdd, onFloorDelete }: FloorMenuProps) => {

    const handleChange = useCallback((type: keyof Floor) => (value: string) => {
        if (!selectedFloor) return
        if (type === "yMax" || type === "yMin") {
            if (!isNaN(parseFloat(value))) {
                onFloorChange({
                    ...selectedFloor,
                    [type]: parseFloat(value)
                })
                return
            }
            onFloorStateChange({
                ...selectedFloor,
                [type]: value
            })
        }
        onFloorChange({
            ...selectedFloor,
            [type]: value
        })
    }, [onFloorChange, onFloorStateChange, selectedFloor])

    const handleCheck = useCallback((type: keyof Floor) => (value: boolean) => {
        if (!selectedFloor) return
        onFloorChange({
            ...selectedFloor,
            [type]: value
        })
    }, [onFloorChange, selectedFloor])

    return <div className="bg-white w-100 h-100 p-3">
        {
            selectedFloor && <div>
                <LabeledInput
                    type="text"
                    title="Name"
                    value={selectedFloor.displayName || ""}
                    onChange={handleChange("displayName")}
                />
                <LabeledCheckBox
                    title="Is Default"
                    checked={!!selectedFloor.isDefault}
                    onChange={handleCheck("isDefault")}
                />
                <br />
                <LabeledInput
                    type="number"
                    title="Y Max"
                    value={selectedFloor.yMax === undefined ? "" : selectedFloor.yMax}
                    onChange={handleChange("yMax")}
                />
                <LabeledInput
                    type="number"
                    title="Y Min"
                    value={selectedFloor.yMin === undefined ? "" : selectedFloor.yMin}
                    onChange={handleChange("yMin")}
                />
                <Row className="mb-2 g-0">
                    <Col xl={4}>
                        <Button
                            block
                            onClick={() => {
                                onFloorIndexChange(selectedFloor, selectedFloor.index + 1)
                            }}
                        >
                            Move Floor Up <i className="fs-6 bi bi-chevron-double-up" />
                        </Button>
                    </Col>
                    <Col xl={4}>
                        <Button
                            block
                            onClick={() => {
                                onFloorIndexChange(selectedFloor, selectedFloor.index - 1)
                            }}
                        >
                            Move Floor Down <i className="fs-6 bi bi-chevron-double-down" />
                        </Button>
                    </Col>
                </Row>
                <Button
                    color="danger"
                    onClick={() => {
                        onFloorDelete(selectedFloor)
                    }}
                    className="mb-2"
                >
                    Delete Floor
                </Button>
            </div>
        }
        <Button
            color="primary"
            onClick={onFloorAdd}
        >
            Add Floor
        </Button>
    </div>
}

export { FloorMenu }
