import React, { useEffect, useState } from "react"
import { Container, Table } from "reactstrap"

import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css"
import "mapbox-gl/dist/mapbox-gl.css"

import { getMapInstance } from './ScanningData'
import { VideoPlacesTable } from "./scanningDataHook"
import { GetMapVideo } from "../../components/Map/fetch_func"
import { timeStamp } from "console"

interface TableVideoProps {
  daysToCheck: number;
  filterOption: "dateRange" | "lastNDays";
  startDate: Date;
  endDate: Date;
}

export default function TableVideos({ daysToCheck, filterOption, startDate, endDate }: TableVideoProps) {
  let indexVideos: number = 0;
  const [places, setPlaces] = useState<VideoPlacesTable[]>([]);
  const map = getMapInstance();

  useEffect(() => {
    GetMapVideo()
      .then(data => setPlaces(data))
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  const currentTime = Date.now()
  const timesAgo = currentTime - daysToCheck * 24 * 60 * 60 * 1000
  const startTime = startDate.getTime()
  const endTime = endDate.getTime()

  if (!places) return <></>

  const formatTimestamp = (timestamp: number): string => {
    const date = new Date(timestamp)
    const day = date.getDate()
    const month = date.toLocaleString("default", { month: "short" })
    const year = date.getFullYear()

    // Pad single-digit day with leading zero
    const formattedDay = day < 10 ? `0${day}` : `${day}`

    const formattedTime = date.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
    })

    return `${formattedDay}/${month}/${year
      .toString()
      .slice(-2)} ${formattedTime}`
  }

  const filterPlace = (timeStamp: number): boolean => {
    if (filterOption === "lastNDays" && checkLastDays(timeStamp)) return true
    if (filterOption === "dateRange" && checkDateRange(timeStamp)) return true
    return false
  }

  const checkDateRange = (timestamp: number): boolean => {
    return timestamp >= startTime && timestamp <= endTime
  }

  const checkLastDays = (timestamp: number): boolean => {
    return timestamp >= timesAgo
  }

  const DisplayPlaces = places.map((info) => {
    if (!filterPlace(parseInt(info.created_at))) {
      return null
    }

    const handleCellClick = (coords: [number, number]) => {
      var midpoint = coords
      map.flyTo({
        center: midpoint,
        essential: true, // this animation is considered essential with respect to prefers-reduced-motion
        zoom: 18,
        speed: 1.5,
        curve: 1.5,
      })
      window.scrollTo({ top: 0, behavior: "smooth" })
    }
    return (
      <tr onClick={() => handleCellClick(info.startlocation.coordinates)}>
        <td>{++indexVideos}</td>
        <td>{info.display_name}</td>
        <td>{formatTimestamp(parseInt(info.created_at))}</td>
      </tr>
    )
  })

  const noVideosWithinLastDays = DisplayPlaces.every(row => row === null)

  return (
    <React.Fragment>
      <Container>
        <Table
          bordered
          style={{ marginTop: "30px", marginBottom: "100px" }}
          className="table-primary"
        >
          <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Created At</th>
            </tr>
          </thead>
          <tbody>
            {noVideosWithinLastDays ? (
              <tr>
                <td>No Video for past {daysToCheck} days</td>
                <td>No Video for past {daysToCheck} days</td>
                <td>No Video for past {daysToCheck} days</td>
              </tr>
            ) : (
              DisplayPlaces
            )}
          </tbody>
        </Table>
      </Container>
    </React.Fragment>
  )
}