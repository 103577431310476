import * as THREE from 'three'
import { Suspense } from 'react'
import { Canvas, useLoader } from '@react-three/fiber'
import { PLYLoader } from 'three/examples/jsm/loaders/PLYLoader'
import { OrbitControls } from '@react-three/drei'
import { Floor } from "./floorHook"
import { FloorSelector } from "./FloorSelector/floorSelector"
import { PointCloudPlace } from 'common/hooks/placeHook'


interface OwnProps {
    place: PointCloudPlace
    selectedFloor: Floor | undefined
    floors: Floor[]
    onFloorSelect: (floor?: Floor) => void
}


type FloorEditorProps = OwnProps

const FloorEditor = ({ selectedFloor, place, floors, onFloorSelect }: FloorEditorProps) => {

    const handleFloorSelect = (floorId?: string) => {
        const newFloor = floors.find(floor => floor._id === floorId)
        onFloorSelect(newFloor)
    }

    const PointCloud = () => {
        const upperPlane = new THREE.Plane(new THREE.Vector3(0, -1, 0), selectedFloor?.yMax !== undefined ? selectedFloor.yMax : Infinity)
        const lowerPlane = new THREE.Plane(new THREE.Vector3(0, 1, 0), selectedFloor?.yMin !== undefined ? -selectedFloor.yMin : -Infinity)
        const result = useLoader(PLYLoader, place.signed_public_point_cloud_file || "")
        const material = new THREE.PointsMaterial({
            vertexColors: true,
            size: 0.05,
            clipShadows: true,
            clippingPlanes: [upperPlane, lowerPlane]
        });
        const point = new THREE.Points(result, material);
        return <primitive object={point} />
    };

    return (
        <>
            <FloorSelector
                floors={floors}
                selectedFloor={selectedFloor}
                onFloorSelect={handleFloorSelect}
            />

            <Canvas
                camera={{ fov: 75, near: 0.1, far: 1000, position: [10, 5, 40] }}
                // style={{ borderRadius: "4px" }}
                onPointerMissed={() => { }}
                gl={{ antialias: true, toneMapping: THREE.NoToneMapping, localClippingEnabled: true }}
            >
                <color attach="background" args={['#242424']} />
                <ambientLight intensity={3} />
                <spotLight position={[10, 10, 10]} angle={0.15} penumbra={1} />
                <pointLight position={[-10, -10, -10]} />
                <OrbitControls
                    makeDefault
                    enableDamping={false}
                />
                <Suspense fallback={null}>
                    {
                        <PointCloud />
                    }
                </Suspense>
            </Canvas>
        </>
    )
}

FloorEditor.displayName = "FloorEditor"

export { FloorEditor }
