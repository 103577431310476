import React, { useState } from "react"
import {
    Input,
    Button,
    ModalFooter,
    ModalBody,
    CardBody,
    ModalHeader,
    Modal,
    Label,
} from "reactstrap"
import { Reconstruction } from "../ScanningData/scanningDataHook"
import { usePointCloud } from "./pointCloudHook"
import { LabeledInput } from "components/Common/form"
import { Link } from "react-router-dom"
import { PlaceAccessType, PlaceType, PointCloudPlace } from "common/hooks/placeHook"

const placeTypeArray = [
    {
        title: "Indoor",
        value: PlaceType.Indoor,
    },
    {
        title: "Outdoor",
        value: PlaceType.Outdoor,
    },
]

const placeAccessTypeArray = [
    {
        title: "Public",
        value: PlaceAccessType.Public,
    },
    {
        title: "Private",
        value: PlaceAccessType.Private,
    },
]

interface PointCloudModalProps {
    reconstruction?: Reconstruction
    basePointCloud?: PointCloudPlace
    reconstructionsData: Reconstruction[]
    coordinates?: [number, number][]
    isOpen: boolean
    isUpdate: boolean
    toggleModal: () => void
    onSubmit: (name: string) => void
}

const PointCloudModal = ({
    reconstruction,
    basePointCloud,
    reconstructionsData = [],
    coordinates,
    isOpen,
    isUpdate,
    toggleModal,
    onSubmit,
}: PointCloudModalProps) => {
    const {
        pointCloudPlace,
        updatePointCloudPlace,
        updatePointCLoudData,
        submitPointCloudPlace,
        updateDataFromReconstruction,
    } = usePointCloud(isUpdate, reconstruction, basePointCloud, coordinates)
    const [enableSubmit, setEnableSubmit] = useState(true)

    return <Modal isOpen={isOpen} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>{isUpdate ? "Edit " : "Add "}Point Cloud</ModalHeader>
        <ModalBody>
            <CardBody>
                <LabeledInput
                    type="text"
                    title="Name"
                    value={pointCloudPlace?.name}
                    onChange={updatePointCloudPlace("name")}
                />
                <LabeledInput
                    type="text"
                    title="Category"
                    value={pointCloudPlace?.category}
                    onChange={updatePointCloudPlace("category")}
                />
                <LabeledInput
                    type="text"
                    title="Organization ID"
                    value={pointCloudPlace?.organization_id}
                    onChange={updatePointCloudPlace("organization_id")}
                />
                <Label>
                    Type
                </Label>
                <Input
                    type="select"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        updatePointCloudPlace("type")(e.target.value)
                    }}
                    value={pointCloudPlace?.type || ""}
                >
                    <option key={""} value={""}>
                        -
                    </option>
                    {(placeTypeArray).map((place) => (
                        <option key={place.value} value={place.value}>
                            {place.title}
                        </option>
                    ))}
                </Input>
                <Label>
                    Access Type
                </Label>
                <Input
                    type="select"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        updatePointCloudPlace("access_type")(e.target.value)
                    }}
                    value={pointCloudPlace?.access_type || ""}
                >
                    <option key={""} value={""}>
                        -
                    </option>
                    {(placeAccessTypeArray).map((place) => (
                        <option key={place.value} value={place.value}>
                            {place.title}
                        </option>
                    ))}
                </Input>
                <h4>Point Cloud Data</h4>
                <Label>
                    Fill from reconstruction data
                </Label>
                {
                    isUpdate && <>
                        <Input
                            type="text"
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                const selectedRecon = reconstructionsData.find(recon => recon.id === e.target.value)
                                if (!selectedRecon) return
                                updateDataFromReconstruction(selectedRecon)
                            }}
                            list="reconstructionsDataListForPointCloud"
                        >
                        </Input>
                        <datalist id="reconstructionsDataListForPointCloud">
                            {reconstructionsData.map((reconstructionData) => (
                                <option key={reconstructionData.id} value={reconstructionData.id}>
                                    {reconstructionData.scan_data.display_name} - {(new Date(reconstructionData?.created_at || "")).toLocaleString()}
                                </option>
                            ))}
                        </datalist>
                    </>
                }
                <LabeledInput
                    type="text"
                    title="features"
                    value={pointCloudPlace?.data.features}
                    onChange={updatePointCLoudData("features")}
                />
                <LabeledInput
                    type="text"
                    title="db_descriptors"
                    value={pointCloudPlace?.data.db_descriptors}
                    onChange={updatePointCLoudData("db_descriptors")}
                />
                <LabeledInput
                    type="text"
                    title="idx2img"
                    value={pointCloudPlace?.data.idx2img}
                    onChange={updatePointCLoudData("idx2img")}
                />
                <LabeledInput
                    type="text"
                    title="sfm_images"
                    value={pointCloudPlace?.data.sfm_images}
                    onChange={updatePointCLoudData("sfm_images")}
                />
                <LabeledInput
                    type="text"
                    title="sfm_points3D"
                    value={pointCloudPlace?.data.sfm_points3D}
                    onChange={updatePointCLoudData("sfm_points3D")}
                />
                <LabeledInput
                    type="text"
                    title=".ply Point Cloud File"
                    value={pointCloudPlace?.public_point_cloud_file}
                    onChange={updatePointCloudPlace("public_point_cloud_file")}
                />
                <LabeledInput
                    type="text"
                    title=".splat Point Cloud File"
                    value={pointCloudPlace?.public_splat_file}
                    onChange={updatePointCloudPlace("public_splat_file")}
                />
                <Link to={`/floor?place_id=${pointCloudPlace?._id}`}>
                    <Button color="primary">Floor Configuration</Button>
                </Link>
                <Link to={`/coordinate?place_id=${pointCloudPlace?._id}`}>
                    <Button color="primary">Gps Coordinate Configuration</Button>
                </Link>
            </CardBody>
        </ModalBody>
        <ModalFooter className="justify-content-center">
            <Button
                color="primary"
                disabled={!enableSubmit}
                onClick={() => {
                    setEnableSubmit(false)
                    submitPointCloudPlace().then(res => {
                        if (res) {
                            alert("submit pointcloud success")
                            onSubmit(pointCloudPlace?.name || "")
                        } else {
                            alert("submit pointcloud failed")
                        }
                        setEnableSubmit(true)
                    })
                }}
                style={{ marginBottom: "50px" }}
            >
                Submit
            </Button>
        </ModalFooter>
    </Modal>
}

export { PointCloudModal }
