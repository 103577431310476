import React, { useRef, useState } from "react"
import { useFrame } from "@react-three/fiber"
import { Box } from "@react-three/drei"
import { DragControls } from "three/examples/jsm/controls/DragControls"
import { useThree } from "@react-three/fiber"

const DraggableBox: React.FC<{ onDrag: (isDragging: boolean) => void }> = ({
  onDrag,
}) => {
  const meshRef = useRef<THREE.Mesh>(null)
  const { gl, camera } = useThree()

  // Initialize the DragControls when the component mounts
  React.useEffect(() => {
    let dragControls: DragControls | null = null

    if (meshRef.current) {
      const handleStart = () => {
        onDrag(true) // Notify the parent component about dragging start
      }

      const handleEnd = () => {
        onDrag(false) // Notify the parent component about dragging end
      }

      dragControls = new DragControls([meshRef.current], camera, gl.domElement)
      dragControls.addEventListener("dragstart", handleStart)
      dragControls.addEventListener("dragend", handleEnd)
    }

    return () => {
      if (dragControls) {
        dragControls.dispose()
      }
    }
  }, [camera, gl.domElement])

  return (
    <Box ref={meshRef} position={[0, 60, 0]} args={[5, 5, 5]}>
      <meshStandardMaterial attach="material" color="blue" />
    </Box>
  )
}

export default DraggableBox
