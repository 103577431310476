import { PlaceOrigin, PointCloudPlace } from "common/hooks/placeHook"
import { LabeledInput, LabeledTextBox } from "components/Common/form"
import { useEffect, useState } from "react"
import { Button, Label } from "reactstrap"
import { Euler, Quaternion } from "three"

interface CoordinateMenuProps {
    place: PointCloudPlace
    onPlaceChange: (place: PointCloudPlace) => void
    onPlaceSubmit: () => void
}

const CoordinateMenu = ({ place, onPlaceChange, onPlaceSubmit }: CoordinateMenuProps) => {
    const [rotationBuffer, setRotationBuffer] = useState({
        x: 0,
        y: 0,
        z: 0
    })

    const onOriginChange = (origin: PlaceOrigin) => {
        onPlaceChange({
            ...place,
            originCoordinate: origin,
        })
    }

    const onRotationBufferChange = (x: number, y: number, z: number) => {
        setRotationBuffer({
            x, y, z
        })
    }

    const onRotationChange = (x: number, y: number, z: number) => {
        // onRotationBufferChange(x, y, z)
        const rotation = new Euler(
            x % 360 * (Math.PI / 180),
            y % 360 * (Math.PI / 180),
            z % 360 * (Math.PI / 180),
        )
        const quaternionRotation = new Quaternion().setFromEuler(rotation)
        onOriginChange({
            ...place.originCoordinate!,
            displayRotation: {
                w: quaternionRotation.w,
                x: quaternionRotation.x,
                y: quaternionRotation.y,
                z: quaternionRotation.z,
            }
        })
    }

    useEffect(() => {
        if (!place.originCoordinate) return
        const placeRotation = place.originCoordinate?.displayRotation
        const rotation = new Quaternion(
            placeRotation.x,
            placeRotation.y,
            placeRotation.z,
            placeRotation.w,
        )
        const eulerRotation = new Euler().setFromQuaternion(rotation)
        setRotationBuffer({
            x: Math.round(eulerRotation.x * 100 * 180 / Math.PI) / 100,
            y: Math.round(eulerRotation.y * 100 * 180 / Math.PI) / 100,
            z: Math.round(eulerRotation.z * 100 * 180 / Math.PI) / 100,
        })
    }, [place.originCoordinate?.displayRotation])

    if (!place.originCoordinate) return <></>

    return <div className="bg-white w-100 h-100 p-3">
        <Label>Position</Label>
        <div>
            <LabeledInput
                type="number"
                title="Longtitude"
                value={place.originCoordinate?.displayOriginCoordinates[0]}
                onChange={(val) => {
                    onOriginChange({
                        ...place.originCoordinate!,
                        displayOriginCoordinates: [
                            parseFloat(val),
                            place.originCoordinate?.displayOriginCoordinates[1] || 0,
                            place.originCoordinate?.displayOriginCoordinates[2] || 0,
                        ]
                    })
                }}
            />
            <LabeledInput
                type="number"
                title="Latitude"
                value={place.originCoordinate?.displayOriginCoordinates[1]}
                onChange={(val) => {
                    onOriginChange({
                        ...place.originCoordinate!,
                        displayOriginCoordinates: [
                            place.originCoordinate?.displayOriginCoordinates[0] || 0,
                            parseFloat(val),
                            place.originCoordinate?.displayOriginCoordinates[2] || 0,
                        ]
                    })
                }}
            />
            <LabeledInput
                type="number"
                title="Altitude"
                value={place.originCoordinate?.displayOriginCoordinates[2]}
                onChange={(val) => {
                    onOriginChange({
                        ...place.originCoordinate!,
                        displayOriginCoordinates: [
                            place.originCoordinate?.displayOriginCoordinates[0] || 0,
                            place.originCoordinate?.displayOriginCoordinates[1] || 0,
                            parseFloat(val),
                        ]
                    })
                }}
            />
            <Label>Rotation</Label>
            <div>
                <LabeledTextBox
                    title="X-axis"
                    type="number"
                    value={rotationBuffer.x}
                    onStateChange={(val) => {
                        onRotationBufferChange(parseFloat(val), rotationBuffer.y, rotationBuffer.z)
                    }}
                    onChange={(val) => {
                        onRotationChange(parseFloat(val), rotationBuffer.y, rotationBuffer.z)
                    }}
                />
            </div>
            <div>
                <LabeledTextBox
                    title="Y-axis"
                    type="number"
                    value={rotationBuffer.y}
                    onStateChange={(val) => {
                        onRotationBufferChange(rotationBuffer.x, parseFloat(val), rotationBuffer.z)
                    }}
                    onChange={(val) => {
                        onRotationChange(rotationBuffer.x, parseFloat(val), rotationBuffer.z)
                    }}
                />
            </div>
            <div>
                <LabeledTextBox
                    title="Z-axis"
                    type="number"
                    value={rotationBuffer.z}
                    onStateChange={(val) => {
                        onRotationBufferChange(rotationBuffer.x, rotationBuffer.y, parseFloat(val))
                    }}
                    onChange={(val) => {
                        onRotationChange(rotationBuffer.x, rotationBuffer.y, parseFloat(val))
                    }}
                />
            </div>
        </div>
        <Button
            color="primary"
            onClick={onPlaceSubmit}
        >
            Submit
        </Button>
    </div>
}

export { CoordinateMenu }
