import { PlaceAccessType, PlaceType, PointCloudData, PointCloudPlace, PolygonLocation, usePlace } from "common/hooks/placeHook"
import { Reconstruction } from "modules/ScanningData/scanningDataHook"
import React, { useCallback, useEffect, useState } from "react"

const getPointCloudData = (reconstruction: Reconstruction): PointCloudData => ({
    reconstruction_id: reconstruction.id || "",
    features: `${reconstruction.storage_link}/feats.h5`,
    db_descriptors: `${reconstruction.storage_link}/image_retrievals/db_descriptors.npy`,
    idx2img: `${reconstruction.storage_link}/image_retrievals/idx2img.pkl`,
    sfm_images: `${reconstruction.storage_link}/sfm/aligned_model_y_up/images_compressed.pkl`,
    sfm_points3D: `${reconstruction.storage_link}/sfm/aligned_model_y_up/points3D_compressed.pkl`,
})

const getPublicPointCloudFile = (reconstruction: Reconstruction): string => `${reconstruction.storage_link}/sfm/aligned_model_y_up/model.ply`

export const usePointCloud = (
    isUpdate: boolean,
    reconstruction?: Reconstruction,
    pointCloud?: PointCloudPlace,
    coordinates?: [number, number][],
) => {
    const { place: pointCloudPlace, updatePlaceState, submitPlace: submitPointCloudPlace } = usePlace()
    const location: PolygonLocation | undefined = coordinates && {
        Type: "Polygon",
        coordinates: [coordinates],
    }

    const initPointCloudPlace = (reconstruction: Reconstruction) => {
        let storageLink = reconstruction.storage_link || ""
        if (storageLink[-1] === "/") storageLink = storageLink.slice(0, -1)
        const newPointCloudPlace: PointCloudPlace = {
            name: "",
            category: "",
            data: getPointCloudData(reconstruction),
            type: PlaceType.Indoor,
            access_type: PlaceAccessType.Public,
            public_point_cloud_file: getPublicPointCloudFile(reconstruction),
            maxtvec: 500,
            location,
        }
        updatePlaceState(newPointCloudPlace)
    }

    const updateDataFromReconstruction = (reconstruction: Reconstruction) => {
        if (!pointCloudPlace) {
            return
        }

        const newPointCloudPlace: PointCloudPlace = {
            ...pointCloudPlace,
            data: getPointCloudData(reconstruction),
            public_point_cloud_file: getPublicPointCloudFile(reconstruction),
        }
        updatePlaceState(newPointCloudPlace)
    }

    const updatePointCloudPlace = (key: keyof (PointCloudPlace)) => (value: any) => {
        if (!pointCloudPlace) return
        updatePlaceState({
            ...pointCloudPlace,
            [key]: value
        })
    }

    const updatePointCLoudData = (key: keyof (PointCloudData)) => (value: string) => {
        if (!pointCloudPlace) return
        updatePlaceState({
            ...pointCloudPlace,
            data: {
                ...pointCloudPlace.data,
                [key]: value,
            }
        })
    }

    const initFromExisting = (pointCloudPlace: PointCloudPlace) => {
        updatePlaceState(pointCloudPlace)
    }

    useEffect(() => {
        if (isUpdate && pointCloud) {
            initFromExisting(pointCloud)
            return
        }
        if (!isUpdate && reconstruction) {
            initPointCloudPlace(reconstruction)
        }
    }, [reconstruction, pointCloud, isUpdate, coordinates])

    return {
        pointCloudPlace,
        updatePointCloudPlace,
        updatePointCLoudData,
        submitPointCloudPlace,
        updateDataFromReconstruction,
    }
}
