import { generateBaseHeader } from "api/apiHelper";
import { GetMapVideoAPI, GetPointCloudAPI, GetUserAPIUrl } from "api/mappers_api"

export const GetMapVideo = async () => {
  const header = await generateBaseHeader()
    const fetchResult = await fetch(GetMapVideoAPI(),{
        method: "GET",
        headers: {
          ...header,
        }
    })
    return await fetchResult.json();
}

export const GetUser = async () => {
  const header = await generateBaseHeader()
  const fetchResult = await fetch(GetUserAPIUrl(),{
      method: "GET",
      headers: {
        ...header,
      }
  })

  return await fetchResult.json();
}