import { useThree } from '@react-three/fiber';
import { useGesture } from '@use-gesture/react';
import { animated, useSpring } from '@react-spring/three';

import { useMapControls } from './map_controls';

export const DraggableBox = () => {
  const { size, viewport } = useThree();
  const { enableCamera, disableCamera } = useMapControls();
  const [spring, api] = useSpring(() => ({
    scale: [1, 1, 1],
    position: [0, 0, 0],
    rotation: [0, 0, 0]
  }));
  const aspect = size.width / viewport.width;
  const bind = useGesture({
    onDragStart() {
      disableCamera();
    },
    onDrag({ offset: [x, y] }) {
      return api.start({ position: [x / aspect, 0, y / aspect] });
    },
    onDragEnd() {
      enableCamera();
    }
  });

  return (
    <animated.mesh {...spring} {...bind()} scale={0.5} castShadow>
      <boxGeometry args={[1, 1, 1]} />
      <meshPhongMaterial color="red" />
    </animated.mesh>
  );
};
