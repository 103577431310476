import { Navigate } from "react-router-dom"

// Profile
import UserProfile from "../modules/Authentication/user-profile"

// Authentication related pages
import Login from "../modules/Authentication/Login"
import Logout from "../modules/Authentication/Logout"


// Dashboard
import Dashboard from "../modules/Dashboard/index"

// Map
import Map_3D from "../modules/Map/Map_3D_Model"
import MappersUser from "../modules/Map/MappersUser"
import ScanningData from "../modules/ScanningData/ScanningData"
import PointCloud from "../modules/PointCloud/PointCloud"
import Mappers_Controls from "../modules/Map/Mappers_Controls"
import Three_Drag from "../modules/Map/Three_Drag"

// Editor
import { Floor } from "../modules/Floor/Floor"

//Icons
import IconBoxicons from "../modules/Icons/IconBoxicons"
import IconDripicons from "../modules/Icons/IconDripicons"
import IconMaterialdesign from "../modules/Icons/IconMaterialdesign"
import IconFontawesome from "../modules/Icons/IconFontawesome"
import Coordinate from "modules/Coordinate/Coordinate"

const authProtectedRoutes = [
  { path: "/dashboard", component: <Dashboard /> },

  // Map
  { path: "/map", component: <Map_3D /> },
  { path: "/scanning-data", component: <ScanningData /> },
  { path: "/point-clouds", component: <PointCloud /> },
  { path: "/mappers-user", component: <MappersUser /> },
  { path: "/mappers-controls", component: <Mappers_Controls /> },
  { path: "/three-drag", component: <Three_Drag /> },

  // Editor
  { path: "/floor", component: <Floor /> },
  { path: "/coordinate", component: <Coordinate /> },

  // //profile
  { path: "/profile", component: <UserProfile /> },

  // Icons
  { path: "/icons-boxicons", component: <IconBoxicons /> },
  { path: "/icons-dripicons", component: <IconDripicons /> },
  { path: "/icons-materialdesign", component: <IconMaterialdesign /> },
  { path: "/icons-fontawesome", component: <IconFontawesome /> },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
]

const publicRoutes = [
  { path: "/login", component: <Login /> },
  { path: "/logout", component: <Logout /> },


]

export { authProtectedRoutes, publicRoutes }
