import React, { useEffect, useRef, useState } from "react"
import mapboxgl from "mapbox-gl"

import "mapbox-gl/dist/mapbox-gl.css"
import ThreeModel from "./three_model"
import ReactDOM from "react-dom"
import { Button } from "reactstrap"

// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default

const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN!

const mapCenterCoor: [number, number] = [100.530737, 13.746173]

let mapInstance: mapboxgl.Map
export const getMapInstance = () => mapInstance

export default function MapBox() {
  const mapContainer = useRef<HTMLDivElement>(null)
  const [map, setMap] = useState<mapboxgl.Map>()

  const initMap = () => {
    const attachMap = (
      setMap: React.Dispatch<React.SetStateAction<any>>,
      mapContainer: React.RefObject<HTMLDivElement>
    ) => {
      const mapInit = new mapboxgl.Map({
        container: mapContainer.current || "",
        style: "mapbox://styles/mapbox/satellite-streets-v12",
        center: mapCenterCoor,
        zoom: 5,
        pitch: 45,
        accessToken: MAPBOX_TOKEN,
      })
      setMap(mapInit)
      mapInstance = mapInit
    }
    !map && attachMap(setMap, mapContainer)
  }

  const boxControls = () => {
    // Create a custom DOM element for the custom marker
    const customMarkerContainer = document.createElement("div")
    customMarkerContainer.style.cssText = `
    position: absolute;
    top: 30px;
    right: 20px;
    width: 100px;
    height: 100px;
    background-color: rgba(0, 0, 255, 0.7);
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 14px;
`

    // Add the text for the marker
    const markerText = document.createElement("div")
    markerText.innerText = "Click here to test"
    markerText.style.cssText = `
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
`

    customMarkerContainer.appendChild(markerText)

    // Append the custom marker container to the map container
    const mapContainer = mapInstance.getContainer()
    mapContainer.appendChild(customMarkerContainer)

    // Create the form components using Reactstrap
    const threeModelComponent = (
      <div>
        <ThreeModel />
        <Button
          color="danger"
          onClick={closeForm}
          size="lg"
          style={{
            position: "fixed",
            width: "10vw",
            height: "75vh",
          }}
        >
          Close the controls
        </Button>
      </div>
    )

    // Render the form components inside the form container
    var threeModelContainer = document.createElement("div")
    ReactDOM.render(threeModelComponent, threeModelContainer)

    // Function to display the form when the marker is clicked
    function displayForm() {
      // Append the form container to the map container
      console.log("Marker clicked")
      mapContainer.appendChild(threeModelContainer)
    }

    function closeForm() {
      // Remove the form container from the map container
      threeModelContainer.remove()
    }

    // Add event listener to the marker
    customMarkerContainer.addEventListener("click", displayForm)
  }

  useEffect(() => {
    initMap()
    boxControls()
    // Clean up the map instance when the component unmounts
    return () => {
      if (mapInstance) {
        mapInstance.remove()
      }
    }
  }, [])

  return (
    <React.Fragment>
      <div ref={mapContainer} style={{ height: "75vh", marginTop: "70px" }} />
    </React.Fragment>
  )
}
