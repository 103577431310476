import axios from "axios"
import { generateBaseHeader } from "./apiHelper";
import getBackendUrl from "const";
import { Floor } from "modules/Floor/floorHook";

const backendUrl = getBackendUrl()

export const getFloors = async (placeId: string): Promise<Floor[]> => {
    const header = await generateBaseHeader()
    const resp = await axios({
        method: "GET",
        url: backendUrl + "/floor/" + placeId,
        headers: {
            ...header,
        },
    })

    if (resp.status === 200) {
        return resp.data;
    }
    console.error("getFloors fail");
    return [];
}

export const createOrUpdateFloor = async (floor: Floor): Promise<boolean> => {
    const header = await generateBaseHeader()
    try {
        const resp = await axios({
            method: "POST",
            url: backendUrl + "/floor/create-or-update",
            headers: {
                ...header,
            },
            data: JSON.stringify(floor),
        })

        if (resp.status === 200) {
            return true;
        }
    } catch (error) {
        console.error(error)
    }
    return false;
}

export const updateMultiFloor = async (floors: Floor[]): Promise<boolean> => {
    const header = await generateBaseHeader()
    try {
        const resp = await axios({
            method: "PUT",
            url: backendUrl + "/floor/index",
            headers: {
                ...header,
            },
            data: JSON.stringify(floors),
        })

        if (resp.status === 200) {
            return true;
        }
    } catch (error) {
        console.error(error)
    }
    return false;
}
