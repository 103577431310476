import React from "react"

import MapThree from "components/Map/three-drag/map_tree"

export default function ThreeDrag() {
  return (
    <React.Fragment>
        <MapThree/>
    </React.Fragment>
  )
}
