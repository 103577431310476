import React, { useState } from "react"
import { Input } from "reactstrap"

import "mapbox-gl/dist/mapbox-gl.css"
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css"

import mapboxgl from "mapbox-gl"

interface CoorInputProps {
  map?: mapboxgl.Map
}

export default function CoorInput({ map }: CoorInputProps) {
  const [coordinatesInput, setCoordinatesInput] = useState("")

  const flyToCoordinates = () => {
    if (!map) return
    const [latitude, longitude] = coordinatesInput.split(",")
    const center = [parseFloat(longitude), parseFloat(latitude)] as [number, number]

    if (!isNaN(center[0]) && !isNaN(center[1])) {
      map.flyTo({
        center: center,
        essential: true,
        zoom: 15,
        speed: 1.5,
        curve: 1.5,
      })
    } else {
      alert("Invalid coordinates format")
    }
  }

  return (
    <React.Fragment>
      <Input
        style={{ marginBottom: "10px" }}
        placeholder="Fly to Location (Enter latitude,longitude)"
        value={coordinatesInput}
        onChange={e => setCoordinatesInput(e.target.value)}
        onKeyPress={e => {
          if (e.key === "Enter") {
            flyToCoordinates()
          }
        }}
      />
      <hr
        style={{
          border: "none",
          borderTop: "1px solid black",
          margin: "10px 0",
        }}
      />
    </React.Fragment>
  )
}
