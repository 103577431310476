import { useSearchParams } from "react-router-dom";
import { useFloor, useFloorsData } from "./floorHook"
import { Col, Container, Row } from "reactstrap";
import { FloorEditor } from "./FloorEditor";
import { FloorMenu } from "./FloorMenu";
import { usePlaceData } from "common/hooks/placeHook";

const Floor = () => {
    const [urlParams] = useSearchParams();
    const placeId = urlParams.get('place_id');

    const {
        floors,
        onFloorIndexChange,
        onFloorAdd,
        onFloorChange,
        onFloorStateChange,
        onFloorDelete,
        selectedFloor,
        onFloorSelect,
    } = useFloor(placeId || "")
    const [place] = usePlaceData(placeId || "")
    // debugger
    if (!placeId || !place) return

    return (
        <div style={{ paddingTop: "70px", paddingBottom: "60px", height: "100vh" }}>
            <Container fluid className="h-100 g-0" >
                <Row className="h-100" >
                    <Col lg={8} className="align-items-center h-100 g-0">
                        <FloorEditor
                            floors={floors}
                            place={place}
                            selectedFloor={selectedFloor}
                            onFloorSelect={onFloorSelect}
                        />
                    </Col>
                    <Col lg={4} className="h-100 g-0">
                        <FloorMenu
                            selectedFloor={selectedFloor}
                            onFloorChange={onFloorChange}
                            onFloorIndexChange={onFloorIndexChange}
                            onFloorStateChange={onFloorStateChange}
                            onFloorAdd={onFloorAdd}
                            onFloorDelete={onFloorDelete}
                        />
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

Floor.displayName = "Floor"

export { Floor }
