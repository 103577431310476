import { getStaticMapImage } from "api/map"
import { PointCloudPlace } from "common/hooks/placeHook"
import { MercatorCoordinate } from "mapbox-gl"
import { useEffect, useState } from "react"
import { Box3, Vector3 } from "three"

const useStaticMap = (place: PointCloudPlace | undefined, BoundingBox: Box3 | undefined, shouldUpdate = true) => {
    const [scale, setScale] = useState(0)
    const [image, setImage] = useState<string>()
    const mapResolution = 1280
    useEffect(() => {
        if (!image) {
            updateStaticMap()
        }
    }, [BoundingBox])

    const updateStaticMap = () => {
        if (!place || !BoundingBox) {
            return
        }
        const origin = place.originCoordinate?.displayOriginCoordinates || place.location?.coordinates[0][0]
        if (!origin) return
        const mercatorCoord = MercatorCoordinate.fromLngLat([origin[0], origin[1]], origin[2] || 0)
        const meterScale = mercatorCoord.meterInMercatorCoordinateUnits()
        const center = new Vector3()
        const size = new Vector3()
        BoundingBox.getCenter(center)
        BoundingBox.getSize(size)
        // const mapOrigin = {
        //     x: mercatorCoord.x + center.x * meterScale,
        //     y: mercatorCoord.y + center.y * meterScale,
        // }
        const mapOrigin = {
            x: mercatorCoord.x,
            y: mercatorCoord.y,
        }
        const borderOffset = Math.max(...size.toArray()) * 1
        const borderOffsetMercator = borderOffset * meterScale
        const maxCoord = new MercatorCoordinate(mapOrigin.x + borderOffsetMercator, mapOrigin.y + borderOffsetMercator).toLngLat()
        const minCoord = new MercatorCoordinate(mapOrigin.x - borderOffsetMercator, mapOrigin.y - borderOffsetMercator).toLngLat()
        const mapImage = getStaticMapImage([minCoord.lng, maxCoord.lat, maxCoord.lng, minCoord.lat], mapResolution)
        setScale(borderOffset * 2 / mapResolution)
        setImage(mapImage)
    }

    return {
        scale,
        image,
        updateStaticMap,
    }
}

const calculateCoordinateFromMovement = (origin: number[], transformation: Vector3): number[] => {
    const originCoord = MercatorCoordinate.fromLngLat([origin[0], origin[1]], origin[2] || 0)
    const meterScale = originCoord.meterInMercatorCoordinateUnits()
    const mercatorCoord = new MercatorCoordinate(
        originCoord.x - transformation.z * meterScale,
        originCoord.y + transformation.x * meterScale,
        transformation.y,
    )
    const resultCoord = mercatorCoord.toLngLat()
    return [resultCoord.lng, resultCoord.lat, transformation.y]
}

export { useStaticMap, calculateCoordinateFromMovement }
