import { useEffect, useMemo, useState } from "react"
import { Canvas } from "@react-three/fiber"
import {
  Environment,
  OrbitControls,
  ContactShadows,
  PerspectiveCamera,
} from "@react-three/drei"
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader"
import DraggableBox from "./model_drag"

const numBuildings = 1200

interface Building {
  position: [number, number, number]
  size: [number, number, number]
}

function generateBuilding(): Building {
  const angle = Math.random() * Math.PI * 2
  const distance = Math.random() * 70 + 20
  const x = Math.cos(angle) * distance
  const z = Math.sin(angle) * distance
  const y = Math.random() * 10 + 5
  const size: [number, number, number] = [
    Math.random() * 2 + 2,
    y,
    Math.random() * 2 + 2,
  ]
  return { position: [x, y / 2, z], size }
}

export default function MapThree() {
  const [model, setModel] = useState(null)
  const buildings = useMemo(() => {
    return Array.from({ length: numBuildings }, generateBuilding)
  }, [numBuildings])
  const [isBoxDragging, setIsBoxDragging] = useState(false) // Add this state

  useEffect(() => {
    const loader = new GLTFLoader()
    loader.load(
      "https://docs.mapbox.com/mapbox-gl-js/assets/34M_17/34M_17.gltf", // Load model 
      gltf => {
        setModel(gltf.scene)
        console.log("success")
      },
      undefined,
      error => {
        console.error("Error loading GLTF model:", error)
      }
    )
  }, [])

  return (
    <>
      <Canvas style={{ marginTop: "70px", height: "85vh" }}>
        <Environment preset="city" ground />
        <ambientLight intensity={0.5} />
        <directionalLight
          position={[30, 50, -30]}
          intensity={0.8}
          castShadow
          shadow-mapSize-width={1024}
          shadow-mapSize-height={1024}
        />
        {buildings.map((building, index) => (
          <mesh
            key={index}
            position={building.position}
            receiveShadow
            castShadow
          >
            <boxGeometry args={building.size} />
            <meshStandardMaterial color="#d1bab7" roughness={0} />
          </mesh>
        ))}
        {model && <primitive object={model} />} 
        <DraggableBox onDrag={setIsBoxDragging} />
        <ContactShadows
          blur={2}
          far={10}
          opacity={1}
          position={[0, 0, 0]}
          resolution={1024}
          scale={100}
        />
        {isBoxDragging ? null : (
          <OrbitControls
            maxPolarAngle={Math.PI / 2.1}
            minDistance={15}
            maxDistance={100}
          />
        )}
        <PerspectiveCamera makeDefault position={[60, 30, 70]} fov={90} />
      </Canvas>
    </>
  )
}
