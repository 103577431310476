import axios from "axios"
import { Pipeline, Reconstruction } from "modules/ScanningData/scanningDataHook";
import { generateBaseHeader } from "./apiHelper";
import getBackendUrl from "const";

const backendUrl = getBackendUrl()

export const getPipelines = async (): Promise<Pipeline[]> => {
    const header = await generateBaseHeader()
    const resp = await axios({
        method: "GET",
        url: backendUrl + "/pipelines",
        headers: {
            ...header,
        },
    })

    if (resp.status === 200) {
        return resp.data;
    }
    console.error("getPipelines fail");
    return [];
}

export const getReconstructions = async (): Promise<Reconstruction[]> => {
    const header = await generateBaseHeader()
    const resp = await axios({
        method: "GET",
        url: backendUrl + "/reconstructions",
        headers: {
            ...header,
        },
    })

    if (resp.status === 200) {
        return resp.data;
    }
    console.error("get reconstructions fail");
    return [];
}

export const createReconstruction = async (reconstruction: Reconstruction): Promise<boolean> => {
    const header = await generateBaseHeader()
    try {
        const resp = await axios({
            method: "POST",
            url: backendUrl + "/reconstruction",
            headers: {
                ...header,
            },
            data: JSON.stringify(reconstruction),
        })

        if (resp.status === 200) {
            return true;
        }
    } catch (error) {
        console.error(error)
    }
    return false;
}
